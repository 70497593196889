<template>
  <div class="common-product">
    <div class="full-width">
      <goodsList :list="goodsList" :discount="true"></goodsList>
      <div class="flex-center user-pager mt-20">
        <el-pagination
          background
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout=" prev, pager,  next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from '@/components/goodsList'
import { shopProductList } from '@/api/shop'
export default {
  components: {
    goodsList
  },
  data() {
    return {
      goodsList: [],
      page: {
        current: 1,
        size: 30,
        total: 0
      }
    }
  },
  mounted() {
    this.initGoods()
  },
  methods: {
    initGoods() {
      shopProductList({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.goodsList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    currentChange(page) {
      this.page.current = page
      this.initGoods()
    }
  }
}
</script>